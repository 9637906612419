<template>
  <div class="document" v-loading="loading">
    <article-bar v-for="item in tableData" :id="item.id" :key="item.id" :time="item.time" :title="item.title"></article-bar>
    <br/>
    <el-pagination v-model:currentPage="params.page" :page-size="params.limit" background layout="prev, pager, next" :total="totalCount" @current-change="load"></el-pagination>
  </div>
</template>

<script>
import {getArticleList} from '@/api/public'
import ArticleBar from '@/components/ArticleBar'

export default {
  components: {ArticleBar},
  data() {
    return {
      params: {
        type: 'document',
        page: 1,
        limit: 10,
        catalogue: null
      },
      loading: true,
      tableData: [],
      totalCount: 0
    }
  },
  methods: {
    load() {
      getArticleList(this.params).then(resp => {
        const {documents, total} = resp.data
        this.tableData = documents
        this.totalCount = total
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  },
  mounted() {
    this.params.catalogue = this.$route.query.catalogue
    this.load()
  }
}
</script>

<style scoped>
.document {
  text-align: left;
  padding: 40px;
}
</style>
